import React, { useEffect, useState } from 'react';

import { BlogPost } from './BlogPost';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { BlogIndexEntry } from '../../common/common';
import styles from '../../components/components.module.scss';
import { NavigationBar } from '../../components/NavigationBar';
import { getCurrentLocale, readBlogIndex } from '../../common/utils';

const Blog = () => {
  const { t } = useTranslation(['translation', 'blog']);

  const history = useHistory();
  const currentLocale = getCurrentLocale();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [postList, setPostList] = useState<Array<BlogIndexEntry>>([]);

  useEffect(() => {
    readBlogIndex(currentLocale).then(index => {
      setPostList(index);

      if (index && index.length > 0) {
        setCurrentIndex(0);
      }
    }).catch(error => {
      setPostList([]);
      setCurrentIndex(-1);
    });
  }, [currentLocale]);

  if (!postList || postList.length <= 0 || currentIndex < 0 || !postList[currentIndex]) {
    return <>
      <NavigationBar />

      <div className={styles.section}>
        <h1 className={styles.sectionTitle}>
          {t('blog:no blog posts')}
        </h1>

        <div onClick={() => history.push('/')} className={styles.notFoundBackToHome}>
          {t('back to home')}
        </div>
      </div>

    </>
  }

  let next: BlogIndexEntry | undefined = undefined;
  if (currentIndex < (postList.length - 1)) {
    next = postList[currentIndex + 1];
  }

  let prev: BlogIndexEntry | undefined = undefined;
  if (currentIndex > 0) {
    prev = postList[currentIndex - 1];
  }

  return <>
    <NavigationBar />

    <div className={styles.section}>
      <BlogPost
        t={t}
        next={next}
        prev={prev}
        postName={postList[currentIndex].key}
        onClick={(direction) => setCurrentIndex(currentIndex + direction)}
      />
    </div>
  </>
};

export { Blog };
