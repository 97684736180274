import React from 'react';

import { useTranslation } from 'react-i18next';
import { InfoBoxData } from '../../common/common';
import { getCurrentLocale } from '../../common/utils';
import { InfoBoxList } from '../../components/InfoBoxList';
import commonStyles from '../../components/components.module.scss';

const features: Array<InfoBoxData> = [
  { text: 'box-e:box 1' },
  { text: 'box-e:box 2' },
  { text: 'box-e:box 3' },
  { text: 'box-e:box 4' },
];

const nextFeatures: Array<InfoBoxData> = [
  { text: 'box-e:future box 1' },
  { text: 'box-e:future box 2' },
];

const BoxESaaS = () => {
  const { t } = useTranslation(['translation', 'box-e']);
  const languageCode = getCurrentLocale();

  return <>
    <div className={commonStyles.section}>
      <InfoBoxList t={t} data={features} size='4' title={t('box-e:title')} titleVariant='reduced-padding' />

      <div className={commonStyles.youtubePlayerContainer}>
        <iframe
          id='ytplayer'
          className={commonStyles.youtubePlayer}
          title={t('box-e.youtube video title')}
          src={`https://youtube.com/embed/wLSL2I35RAg?hl=${languageCode}&cc_lang_pref=${languageCode}`} />
      </div>

      <InfoBoxList t={t} data={nextFeatures} size='2' title={t('box-e:future title')} titleVariant='reduced-padding' />
    </div>
  </>
};

export { BoxESaaS };
