import React, { useState } from 'react';

import styles from './Mission.module.css';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../components/components.module.scss'
import { ModalNavigationBar } from '../../components/NavigationBar';
import QRCode from 'react-qr-code';

import iOSApp from '../../resources/apps/ios-app.svg';
import androidApp from '../../resources/apps/android-app.png';

interface modalState {
  isOpen: boolean,
  title: string,
  value: string,
}

const appLinks = {
  'ios': 'https://apps.apple.com/app/box-e-scan/id1615010541?itsct=apps_box_link&itscg=30200',
  'android': 'https://play.google.com/store/apps/details?id=io.fourboxes.boxe.codescanner',
};

const Mission = () => {
  const [modal, setModal] = useState<modalState>({ isOpen: false, title: '', value: '' });
  const { t } = useTranslation(['translation', 'mission']);

  const selectModalTarget = (target?: string) => {
    switch (target) {
      case 'ios':
      case 'android':
        setModal({
          isOpen: true,
          title: t(`mission:scan the code to get app.${target}`),
          value: appLinks[target]
        });
        break;
      default:
        setModal({ isOpen: false, title: t('mission:scan the code to get app.-'), value: '' });
        break;
    }
  };

  return <>
    <div className={`${styles.home} ${commonStyles.section} ${commonStyles.white}`}>
      <h1 className={commonStyles.sectionTitle}>
        {t('mission:title')}
      </h1>

      {['1', '2'].map((index, key) => {
        return <p className={commonStyles.homeText} key={key}>{t(`mission:summary.${index}`)}</p>
      })}

      <div
        className={commonStyles.sectionTitleSmallReducedPadding}
        style={{ paddingTop: '2rem', paddingBottom: '0.5rem' }}>
        {t('mission:box-e sign in title')}
      </div>
      <div className={commonStyles.homeText}>
        {t('mission:box-e sign in', { menu: '\u2630' })}
      </div>

      <div
        className={commonStyles.sectionTitleSmallReducedPadding}
        style={{ paddingTop: '2rem', paddingBottom: '0.5rem' }}>
        {t('mission:get app title')}
      </div>
      <div style={{ display: 'flex' }}>
        <img
          height={32} src={iOSApp}
          style={{ cursor: 'pointer' }}
          onClick={() => selectModalTarget('ios')}
          alt={t('mission:scan the code to get app.ios')}
        />

        <div style={{ display: 'flex', width: '1rem' }} />

        <img
          height={32} src={androidApp}
          style={{ cursor: 'pointer' }}
          onClick={() => selectModalTarget('android')}
          alt={t('mission:scan the code to get app.android')}
        />
      </div>
      <div
        className={commonStyles.homeText}
        style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
        {t('mission:get app disclaimer')}
      </div>

      <div className={commonStyles.flexibleSpace} />
      <div className={`${commonStyles.scrollForMore} ${commonStyles.white}`}>
        <h5>{t('scroll for more')}</h5>
        <div className={commonStyles.arrowDown} />
      </div>
    </div>

    <div hidden={!modal.isOpen} className={commonStyles.modalBoxContainer} onClick={() => selectModalTarget(undefined)}>
      <div className={`${commonStyles.modalBox}`}>
        <ModalNavigationBar title={modal.title} />

        <div style={{ paddingTop: '7rem', display: 'flex' }}>
          <div style={{ flexGrow: 1 }} />
          <div style={{ display: 'flex' }}><QRCode value={modal.value} /></div>
          <div style={{ flexGrow: 1 }} />
        </div>

        <div style={{ paddingTop: '1rem' }} onClick={() => { selectModalTarget(undefined); return false; }}
          className={commonStyles.navModalCloseButton}>
          {t('close')}
        </div>
      </div>
    </div>
  </>
};

export { Mission };
