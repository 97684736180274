import React, { FC } from 'react';

import { TFunction } from 'i18next';
import styles from './Roadmap.module.scss';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../components/components.module.scss';

const features: Array<string> = [
  'documents trash',
  'custom print labels',
  'ledger management',
  'e-commerce'
];

type RoadmapFeatureProps = {
  feature: string;
  t?: TFunction;
};

const RoadmapFeature: FC<RoadmapFeatureProps> = ({ feature, t }) => {
  return <div className={styles.gitFeature}>
    <h4 className={styles.roadmapFeatureTitle}>{t && t(`roadmap:${feature}.target`)}</h4>
    <h4 className={styles.roadmapFeatureTarget}>{t && t(`roadmap:${feature}.label`)}</h4>
    <p>{t && t(`roadmap:${feature}.description`)}</p>
    <div className={styles.roadmapFeatureSeparator} />
  </div>
};

const Roadmap = () => {
  const { t } = useTranslation(['translation', 'roadmap']);

  return <div className={`${styles.roadmap} ${commonStyles.section} ${commonStyles.white}`}>
    <h1 className={commonStyles.sectionTitleReducedPadding}>
      {t('roadmap:roadmap')}
    </h1>

    <h3 className={commonStyles.sectionTitleSmallReducedPadding}>
      {t('roadmap:current version')}
    </h3>

    <div className={styles.gitStart} />
    <div className={styles.gitBranch} />

    {features.map((feature, key) => <RoadmapFeature feature={feature} t={t} key={key} />)}

    <div className={styles.gitBranchContinue} />
    <div className={styles.gitMasterContinue} />
  </div>
};

export { Roadmap };
