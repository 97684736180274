import React, { FC, useEffect, useState } from 'react';

import moment from 'moment';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router';
import styles from './BlogPost.module.css';
import ReactMarkdown from 'react-markdown';
import { BlogIndexEntry } from '../../common/common';
import { getFrontMatter } from '../../common/matter';
import commonStyles from '../../components/components.module.scss';
import { getCurrentLocale, readBlogPost } from '../../common/utils';

import 'moment/locale/it';

type BlogPostPropsType = {
  postName: string;

  prev?: BlogIndexEntry;
  next?: BlogIndexEntry;

  t: TFunction;
  onClick: (direction: number) => void;
}

type FrontMatter = {
  author?: string;
  title?: string;
  date?: string;
}

const BlogPost: FC<BlogPostPropsType> = ({ postName, prev, next, t, onClick }) => {
  const currentLocale = getCurrentLocale();

  const history = useHistory();
  const [content, setContent] = useState('');
  const [showError, setShowError] = useState<boolean>(false);
  const [matterData, setMatterData] = useState<FrontMatter>({});

  moment.locale(currentLocale);

  useEffect(() => {
    readBlogPost(currentLocale, postName).then(post => {
      const source = getFrontMatter(post);

      if (source.content.startsWith('<!DOCTYPE html>')) {
        setShowError(true);
        setContent('');
        setMatterData({});
      } else {
        setShowError(false);
        setContent(source.content);
        setMatterData(source.data);
      }
    }).catch(error => {
      setShowError(true);
    });
  }, [currentLocale, content, showError, matterData, postName]);

  if (showError === true) {
    return <>
      <div className={styles.blogPostContainer}>
        <h1 className={commonStyles.sectionTitle}>
          {t('blog:content not found')}
        </h1>

        <div onClick={() => history.push('/blog')} className={commonStyles.notFoundBackToHome}>
          {t('blog:reload blog')}
        </div>
      </div>
    </>
  }

  return <>
    <div className={styles.blogPostContainer}>
      <h1 className={commonStyles.sectionTitle}>
        {matterData.title}
      </h1>

      {matterData.date && <div>{moment(matterData.date).format('LL')}</div>}

      <ReactMarkdown children={content} />

      {
        matterData.author && <>
          <div className={commonStyles.divider} />
          <div className={styles.blogPostAuthor}>
            {t('blog:published by', { author: matterData.author })}
          </div>
        </>
      }

      {
        (prev || next) && <>
          <div className={styles.blogNavigator}>
            {
              prev && <div className={styles.blogNavigatorButtonContainer}>
                <div className={styles.arrowLeft} />
                <div
                  className={`${styles.blogNavigatorButton} ${commonStyles.navbarMenuItem}`}
                  onClick={() => onClick(-1)}>
                  {prev.title}
                </div>
              </div>
            }

            <div className={styles.blogNavigatorSpace} />

            {
              next && <div className={styles.blogNavigatorButtonContainer}>
                <div
                  className={`${styles.blogNavigatorButton} ${commonStyles.navbarMenuItem}`}
                  onClick={() => onClick(1)}>
                  {next.title}
                </div>
                <div className={styles.arrowRight} />
              </div>
            }
          </div>
        </>
      }
    </div>
  </>
};

export { BlogPost };
