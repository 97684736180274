import React, { FC } from 'react';

import { TFunction } from 'i18next';
import styles from './Features.module.scss';
import { Feature } from '../../common/common';
import { PlatformIcons } from './PlatformIcons';

type FeatureRowProps = {
  t?: TFunction;
  feature: Feature;
  isLastFeature: boolean;
  onClick: (feature?: Feature) => void;
}

const FeatureRow: FC<FeatureRowProps> = ({ t, feature, isLastFeature, onClick }) => {
  return (
    <div className={styles.featureRowContainer} onClick={() => onClick(feature)}>
      <div className={isLastFeature ? styles.featureRowLast : styles.featureRow}>
        <div className={styles.featureName}>{t && t(`features:${feature.key}.label`)}</div>
        <div className={styles.featurePlatform}>
          <PlatformIcons web={feature.web} ios={feature.ios} android={feature.android} />
        </div>
        <div className={styles.featureLimitations}>{t && t(`features:${feature.key}.limitations`)}</div>
      </div>
    </div>
  )
};

export { FeatureRow };
