import React from 'react';

import { useTranslation } from 'react-i18next';
import { InfoBoxData } from '../../common/common';
import { InfoBoxList } from '../../components/InfoBoxList';
import commonStyles from '../../components/components.module.scss';

const software: Array<InfoBoxData> = [
  { title: 'team:francesco colleoni.name', text: 'team:francesco colleoni.role' },
  { title: 'team:alessandro colleoni.name', text: 'team:alessandro colleoni.role' },
]
const marketing: Array<InfoBoxData> = [
  { title: 'team:massimo bettonagli.name', text: 'team:massimo bettonagli.role' },
  { title: 'team:vincenzo ferrara.name', text: 'team:vincenzo ferrara.role' },
];
const footer: Array<string> = [
  "footer company name",
  "footer company address",
  "footer company email"
];

const Team = () => {
  const { t } = useTranslation(['translation', 'team']);

  return <>
    <div className={commonStyles.section}>
      <h1 className={commonStyles.sectionTitleReducedPadding}>{t('team:who we are')}</h1>

      <InfoBoxList t={t} data={software} size='2' title={t('team:software development')} titleVariant='small-reduced-padding' />
      <InfoBoxList t={t} data={marketing} size='2' title={t('team:management and marketing')} titleVariant='small-reduced-padding' />

      <div className={commonStyles.siteFooter}>
        {footer.map((textKey, key) => <p key={key}>{t(textKey)}</p>)}
      </div>
    </div>
  </>
};

export { Team };
