import React from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../components/components.module.scss';
import { NavigationBar } from '../components/NavigationBar';

const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return <>
    <NavigationBar />

    <div className={styles.section}>
      <h1 className={styles.sectionTitle}>
        {t('not found title')}
      </h1>

      <p className={styles.homeText}>
        {t('not found text')}
      </p>

      <div onClick={() => history.push('/')} className={styles.notFoundBackToHome}>
        {t('back to home')}
      </div>
    </div>
  </>
};

export { NotFound };
