import React, { useState } from 'react';

import { FeatureRow } from './FeatureRow';
import styles from './Features.module.scss';
import { Feature } from '../../common/common';
import { useTranslation } from 'react-i18next';
import { FeatureContent } from './FeatureContent';
import commonStyles from '../../components/components.module.scss';
import { ModalNavigationBar } from '../../components/NavigationBar';

const features: Array<Feature> = [
  { key: "product catalog", web: true, ios: true, android: true },
  { key: "production tracking", web: true, ios: true, android: true },
  { key: "warehouse management", web: true },
  { key: "advanced documents management", web: true, ios: true, android: true },
  { key: "acl", web: true, ios: true, android: true },
  { key: "order lists and customer management", web: true },
  { key: "print support", web: true },
  { key: "sites management", web: true },
  { key: "users management", web: true },
  { key: "mobile apps", ios: true, android: true },
];

const Features = () => {
  const { t } = useTranslation(['translation', 'features']);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedFeature, setSelectedFeature] = useState<any>(null);

  const selectFeature = (feature?: Feature) => {
    setSelectedFeature(feature);
    setModalIsOpen(feature !== undefined);
  };

  return <>
    <div className={commonStyles.section}>
      <h1 className={commonStyles.sectionTitleReducedPadding}>
        {t('features:title')}
      </h1>
      <div className={styles.featuresTableHeaderContainer}>
        <div className={styles.featuresTableHeader}>
          <div className={styles.featureName}>{t('features:feature')}</div>
          <div className={styles.featurePlatform}>{t('features:platforms')}</div>
          <div className={styles.featureLimitations}>{t('features:limitations')}</div>
        </div>
      </div>

      {
        features.map((feature, key) => <FeatureRow
          t={t}
          key={key}
          feature={feature}
          onClick={selectFeature}
          isLastFeature={key >= features.length - 1}
        />
        )
      }

      <h1 className={commonStyles.sectionTitleReducedPadding}>&nbsp;</h1>
    </div>

    <div hidden={!modalIsOpen} className={commonStyles.modalBoxContainer} onClick={() => selectFeature(undefined)}>
      <div className={`${commonStyles.modalBox}`}>
        <ModalNavigationBar title={selectedFeature && t(`features:${selectedFeature.key}.label`)} />

        <div>{selectedFeature && <FeatureContent feature={selectedFeature} />}</div>

        <div onClick={() => { selectFeature(undefined); return false; }} className={commonStyles.navModalCloseButton}>
          {t('close')}
        </div>
      </div>
    </div>
  </>
};

export { Features };
