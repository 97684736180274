import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import ReactMarkdown from 'react-markdown';
import styles from '../blog/BlogPost.module.css';
import { getFrontMatter } from '../../common/matter';
import commonStyles from '../../components/components.module.scss';
import { getCurrentLocale, readChangelog } from '../../common/utils';

import 'moment/locale/it';
import { useTranslation } from 'react-i18next';
import { NavigationBar } from '../../components/NavigationBar';

const Changelog = () => {
  const { t } = useTranslation(['translation', 'changelog']);

  const currentLocale = getCurrentLocale();

  const history = useHistory();
  const [content, setContent] = useState('');
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    readChangelog(currentLocale).then(changelog => {
      const source = getFrontMatter(changelog);

      if (source.content.startsWith('<!DOCTYPE html>')) {
        setShowError(true);
        setContent('');
      } else {
        setShowError(false);
        setContent(source.content);
      }
    }).catch(error => {
      setShowError(true);
    });
  }, [currentLocale, content, showError]);

  if (showError === true) {
    return <>
      <NavigationBar />

      <div className={commonStyles.section}>
        <div className={styles.blogPostContainer}>
          <h1 className={commonStyles.sectionTitle}>
            {t('changelog:changelog not available')}
          </h1>

          <div onClick={() => history.push('/blog')} className={commonStyles.notFoundBackToHome}>
            {t('changelog:reload changelog')}
          </div>
        </div>
      </div>
    </>
  }

  return <>
    <NavigationBar />

    <div className={commonStyles.section}>
      <div className={styles.blogPostContainer}>
        <h1 className={commonStyles.sectionTitle}>
          {t('changelog:changelog')}
        </h1>

        <ReactMarkdown children={content} />
      </div>
    </div>
  </>
};

export { Changelog };
