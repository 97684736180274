import React, { Suspense } from 'react';

import './i18n';
import './index.css';
import ReactDOM from 'react-dom';
import App from './containers/App';
import { Blog } from './sections/blog/Blog';
import { NotFound } from './containers/NotFound';
import { Changelog } from './sections/changelog/Changelog';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <>
    <Suspense fallback={'...'}>
      <Router>
        <Switch>
          <Route exact path='/' component={App} />
          <Route exact path='/blog' component={Blog} />
          <Route exact path='/changelog' component={Changelog} />

          <Route path='*' component={NotFound} />
        </Switch>
      </Router>
    </Suspense>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
