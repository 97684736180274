import React, { FC } from 'react';

import styles from './Features.module.scss';
import { GrPersonalComputer, GrApple, GrAndroid } from 'react-icons/gr';

type PlatformIconsProps = {
  web?: boolean;
  android?: boolean;
  ios?: boolean;
};

const PlatformIcons: FC<PlatformIconsProps> = ({ web, android, ios }) => {
  return <>
    <div className={styles.platforms}>
      {web && <div className={styles.platformIconPadded}><GrPersonalComputer /></div>}
      {ios && <div className={styles.platformIconPadded}><GrApple /></div>}
      {android && <div className={styles.platformIconPadded}><GrAndroid /></div>}
    </div>
  </>
};

export { PlatformIcons };
