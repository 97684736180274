import React, { FC } from 'react';

import { InfoBox } from './InfoBox';
import { TFunction } from 'i18next';
import styles from './components.module.scss';
import { InfoBoxData } from '../common/common';

const processTitleVariant = (variant?: 'default' | 'reduced-padding' | 'small' | 'small-reduced-padding'): string => {
  switch (variant) {
    case 'reduced-padding':
      return `${styles.title} ${styles.titleBig} ${styles.titleReducedPadding}`
    case 'small':
      return `${styles.title} ${styles.titleSmall} ${styles.titleSectionPadding}`
    case 'small-reduced-padding':
      return `${styles.title} ${styles.titleSmall} ${styles.titleReducedPadding}`
    default:
      return styles.sectionTitle;
  }
}

type InfoBoxListProps = {
  t?: TFunction;
  title?: string;
  data: Array<InfoBoxData>;
  size: '1' | '2' | '3' | '4';

  titleVariant?: 'default' | 'reduced-padding' | 'small' | 'small-reduced-padding';
};

const InfoBoxList: FC<InfoBoxListProps> = ({ title, data, size, t, titleVariant }) => {
  const titleStyle = processTitleVariant(titleVariant);

  return (
    <>
      {title && <h1 className={titleStyle}>{title}</h1>}

      <div className={styles.infoBoxContainer}>
        {
          data.map((box, key) =>
            <InfoBox
              title={box.title && t && t(box.title)}
              text={box.text && t && t(box.text)}
              index={key} size={size} key={key}
            />
          )
        }
      </div>
    </>
  )
};

export { InfoBoxList };
