import React, { FC, useState } from 'react';

import i18next from 'i18next';
import flags from './flags.module.scss';
import styles from './components.module.scss';
import { useHistory } from 'react-router-dom';
import { AiOutlineMenu } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { getCurrentLocale } from '../common/utils';
import blueLogo from '../resources/images/blue-logo.png';

const NavigationBar = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const currentLocale = getCurrentLocale();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const changeLanguage = (lang: string) => {
    i18next.changeLanguage(lang);
    setMenuIsOpen(false);
  };

  return <>
    <div className={`${styles.navbar}`}>
      <img src={blueLogo} width={32} height={32} alt='4boxes' onClick={() => history.push('/')} />
      <div className={styles.navBarTitle}>4BOXES</div>
      <div className={styles.flexibleSpace} />

      <div className={styles.navBarButtonContainer}>
        <div
          className={currentLocale === 'it' ? flags.flag_it : flags.flagClickable_it}
          onClick={currentLocale === 'en' ? () => changeLanguage('it') : undefined} />
      </div>

      <div className={styles.navBarButtonContainer}>
        <div
          className={currentLocale === 'en' ? flags.flag_en : flags.flagClickable_en}
          onClick={currentLocale === 'it' ? () => changeLanguage('en') : undefined} />
      </div>

      <div className={styles.navBarButtonContainer} onClick={() => setMenuIsOpen(!menuIsOpen)}>
        <AiOutlineMenu size={32} />
      </div>
    </div>

    <div hidden={!menuIsOpen} className={styles.modalBoxContainer} onClick={() => setMenuIsOpen(false)}>
      <div className={`${styles.navbarMainMenu}`}>
        <div className={styles.navbarMenuSection}>Box-e</div>

        <a className={styles.navbarMenuItem} target='_blank' rel='noopener noreferrer' href={process.env.REACT_APP_BOX_E_URL}>{t('box-e login')}</a>
        <div className={styles.divider} />
        <a className={styles.navbarMenuItem} target='_blank' rel='noopener noreferrer' href={process.env.REACT_APP_HELP_DESK_URL}>{t('box-e help')}</a>
        <div className={styles.navbarMenuItemHint}>
          {t('box-e help hint')}
        </div>

        <div className={styles.divider} />
        <div className={styles.navbarMenuItem}>
          <div onClick={() => { setMenuIsOpen(false); history.push('/changelog'); return false; }} className={styles.navButton}>
            {t('changelog')}
          </div>
        </div>

        <div className={styles.navbarMenuSection}>About us</div>
        <div className={styles.navbarMenuItem}>
          <div onClick={() => { setMenuIsOpen(false); history.push('/blog'); return false; }} className={styles.navButton}>
            {t('blog')}
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.navbarMenuItem}>
          <a className={styles.navButton} href='mailto:contact@4boxes.io'>{t('contact us')}</a>
        </div>
        <div className={styles.divider} />

        <div className={styles.navbarMenuItem}>
          <div onClick={() => { setMenuIsOpen(false); return false; }} className={styles.navModalCloseButton}>
            {t('close')}
          </div>
        </div>
      </div>
    </div>
  </>
};

type ModalNavigationBarProps = {
  title?: string;
}

const ModalNavigationBar: FC<ModalNavigationBarProps> = ({ title }) => {
  return <>
    <div className={styles.navbarModal}>
      <div className={`${styles.navbarModalText}`}>{title}</div>
    </div>
  </>
}

export { NavigationBar, ModalNavigationBar };
