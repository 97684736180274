import React, { FC } from 'react';

import styles from './components.module.scss';

const processBoxStyle = (size: '1' | '2' | '3' | '4', index: number): string => {
  let allStyles = Array<string>();

  switch (size) {
    case '1':
      allStyles = [styles.infoBoxContentA1];
      break;
    case '2':
      allStyles = [styles.infoBoxContentA2, styles.infoBoxContentB2];
      break;
    case '3':
      allStyles = [styles.infoBoxContentA3, styles.infoBoxContentB3, styles.infoBoxContentC3];
      break;
    case '4':
      allStyles = [styles.infoBoxContentA4, styles.infoBoxContentB4, styles.infoBoxContentC4, styles.infoBoxContentD4];
      break;
  }

  return allStyles[(index % allStyles.length)]; // Ensures that the index is not out of bounds; component InfoBox must not be used directly.
};

const processContainerStyle = (size: '1' | '2' | '3' | '4'): string => {
  switch (size) {
    case '1':
      return styles.infoBox1;
    case '2':
      return styles.infoBox2;
    case '3':
      return styles.infoBox3;
    case '4':
      return styles.infoBox4;
  }
};

type InfoBoxProps = {
  title?: string;
  text?: string;

  index: number;
  size: '1' | '2' | '3' | '4';
};

const InfoBox: FC<InfoBoxProps> = ({ title, text, size, index }) => {
  const containerStyle = processContainerStyle(size);
  const boxStyle = processBoxStyle(size, index);

  return (
    <div className={containerStyle}>
      <div className={`${styles.infoBoxContent} ${boxStyle}`}>
        {title && <div className={styles.infoBoxTitle}><h4>{title}</h4></div>}
        {text && <div>{text}</div>}
      </div>
    </div>
  );
};

export { InfoBox };
