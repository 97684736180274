import i18next from 'i18next';
import { BlogIndexEntry } from './common';

const getCurrentLocale = () => {
  const code = i18next.language.toLocaleLowerCase();

  if (code.includes('-')) {
    const components = code.split('-');
    return components[0];
  }

  return code;
};

const readBlogIndex = async (locale: string): Promise<Array<BlogIndexEntry>> => {
  const response = await fetch(`/blog/${locale}/index.json`);

  if (response.status !== 200) {
    return [];
  }

  const json = await response.json();
  return json.index;
};

const readBlogPost = async (locale: string, fileName: string) => {
  const response = await fetch(`/blog/${locale}/${fileName}.md`);

  if (response.status !== 200) {
    return 'not found';
  }

  return await response.text();
};

const readChangelog = async (locale: string) => {
  const response = await fetch(`/changelog/${locale}/changelog.md`);

  if (response.status !== 200) {
    return 'not found';
  }

  return await response.text();
};

export { getCurrentLocale, readBlogIndex, readBlogPost, readChangelog };
