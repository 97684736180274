import React, { FC } from 'react';

import { Feature } from '../../common/common';
import { useTranslation } from 'react-i18next';
import { PlatformIcons } from './PlatformIcons';
import commonStyles from '../../components/components.module.scss';

type FeatureContentProps = {
  feature?: Feature;
};

const FeatureContent: FC<FeatureContentProps> = ({ feature }) => {
  const { t } = useTranslation(['translation', 'features']);

  if (!feature) {
    return <></>
  }

  const description = t(`features:${feature.key}.description`).split('<br>');

  return <>
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle} />

      {description.map((phrase, key) => <p className={commonStyles.text} key={key}>{phrase}</p>)}

      <div className={commonStyles.divider} />

      <p className={commonStyles.text}>
        {t(`features:${feature.key}.limitations`)}
      </p>

      <div className={commonStyles.divider} />

      <p className={`${commonStyles.text} ${commonStyles.flexbox}`}>
        <div>{t(`features:platforms`)}</div>
        <div className={commonStyles.platformsModal}>
          <PlatformIcons web={feature.web} ios={feature.ios} android={feature.android} />
        </div>
      </p>
    </div>
  </>
};

export { FeatureContent };
