import React from 'react';

import { NavigationBar } from '../components/NavigationBar';
import { Features } from '../sections/features/Features';
import { Roadmap } from '../sections/roadmap/Roadmap';
import { Mission } from '../sections/mission/Mission';
import { BoxESaaS } from '../sections/box-e/Box-e';
import { Team } from '../sections/team/Team';

const App = () => {
  return <>
    <NavigationBar />
    <Mission />
    <BoxESaaS />
    <Features />
    <Roadmap />
    <Team />
  </>
};

export default App;
